import $ from "jquery";
import Masonry from "masonry-layout";

class ListNewsAjax {
    constructor() {

    }

    init() {
        $(document).ready( () => {
            this.pageNumber = 2;
            this.postsPerPage = 6;
            this.container = $('.bl-list-news-ajax');
            if(!this.container.length) { return false;}
            this.btnLoadMore = this.container.find('#btn-load-more');

            this.btnLoadMore.click((e) => {
                e.preventDefault();
                this.ajax();
            });
            this.gridMasonry = null;
            this.startGridMasonry();
        })
    }

    startGridMasonry () {
        this.gridMasonry = new Masonry('.grid', {
            percentPosition: true,
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            gutter: 0,
        });
    }


    ajax() {
        let self = this;
        $.ajax({
            type: "POST",
            url: adminAjax,
            data: {
                action : 'more_post',
                paged : self.pageNumber,
                posts_per_page : self.postsPerPage,
            },
            success: function(data){
                if(data.success) {
                    self.container.find('.grid').append($(data.data)).ready( () => {
                        self.gridMasonry.reloadItems();
                        self.gridMasonry.layout();
                    });
                    self.pageNumber = self.pageNumber +1;
                } else {
                    self.btnLoadMore.attr("disabled",true);
                }
            },
            error : function(data) {
                console.log('error')
            }

        });



    };

    test(data) {

    }
}

export default ListNewsAjax;
