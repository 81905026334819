import $ from "jquery";

// import Swiper JS
import Swiper from "swiper";
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";

class GalerieMediaSecond {
  constructor() {}

  init() {
    this.initSlider();
  }

  initSlider() {
    $(document).ready(function () {
        const swiper = new Swiper(".mySwiper", {
          // Optional parameters
          direction: "horizontal",
          loop: true,
          slidesPerView: 1,
          centeredSlides: true,
          grabCursor: true,
          effect: "creative",
          autoplay: {
            disableOnInteraction: false,
          },
          keyboard: {
            enabled: true,
          },
        });
      
        const photo_swipe_options = {
          gallery: "#my-gallery",
          pswpModule: PhotoSwipe,
          // set background opacity
          bgOpacity: 1,
          showHideOpacity: true,
          children: "a",
          loop: true,
          showHideAnimationType: "zoom" /* options: fade, zoom, none */,
      
          /* Click on image moves to the next slide */
          imageClickAction: "next",
          tapAction: "next",
      
          /* ## Hiding a specific UI element ## */
          zoom: false,
          close: true,
          counter: true,
          arrowKeys: true,
          /* ## Options ## */
          bgOpacity: "1" /* deafult: 0.8 */,
          wheelToZoom: true /* deafult: undefined */,
          captionContent: '.pswp-caption-content'
        };
      
        const lightbox = new PhotoSwipeLightbox(photo_swipe_options);
      
        lightbox.init();
      
        lightbox.on("change", () => {
          const { pswp } = lightbox;
          swiper.slideTo(pswp.currIndex, 0, false);
        });
      
        lightbox.on("afterInit", () => {
          const { pswp } = lightbox;
          if (swiper.params.autoplay.enabled) {
            swiper.autoplay.stop();
          }
          console.log("afterInit");
        });
      
        lightbox.on("closingAnimationStart", () => {
          const { pswp } = lightbox;
          swiper.slideTo(pswp.currIndex, 0, false);
          if (swiper.params.autoplay.enabled) {
            swiper.autoplay.start();
          }
        });
      
        const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
          type: 'auto',
        });
      
        const swiperNext = document.querySelector('.swiper-button-next');
        const swiperPrev = document.querySelector('.swiper-button-prev');
        if (swiperNext) {
          swiperNext.addEventListener('click', function() {
            swiper.slideNext();
          });
        }
        if (swiperPrev) {
          swiperPrev.addEventListener('click', function() {
            swiper.slidePrev();
          });
        }
      });
  }
}

export default GalerieMediaSecond;
