import $ from "jquery";
import "selectric/public/jquery.selectric";

class ComponentForm {
  constructor() {}

  init() {
    $(document).ready(() => {
      this.customCheckbox();
      this.dropdown();
      this.dropdownFAQ();
      this.customSelect();
    });
  }

  /*
   * Event custom checkbox
   */
  customCheckbox() {
    let elements = $(".custom-checkbox-js");
    elements.find("input").change(function (e) {
      if (this.checked) {
        $(e.currentTarget)
          .closest(".custom-checkbox-js")
          .find("label")
          .addClass("checked");
        $(e.currentTarget).closest(".list-dropdown").addClass("items-active");
      } else {
        $(e.currentTarget)
          .closest(".custom-checkbox-js")
          .find("label")
          .removeClass("checked");
      }
    });
  }

  /*
   * Create custom select
   */

  customSelect() {
    $("select").selectric();
  }

  dropdown() {
    let elements = $(".list-dropdown");
    let self = this; // Stocker une référence à l'instance de l'objet

    /* Ouverture et fermeture du dropdown */
    elements.click((e) => {
      if ($(e.currentTarget).hasClass("open")) {
        $(e.currentTarget).find(".btn--dropdown").removeClass("open");
        $(e.currentTarget).removeClass("open");
      } else {
        $(e.currentTarget).find(".btn--dropdown").addClass("open");
        $(e.currentTarget).addClass("open");
      }
    });

    // Gestionnaire d'événements pour les clics en dehors du conteneur "list-dropdown"
    $(document).click((e) => {
      if (!$(e.target).closest(".list-dropdown").length) {
        // Clic en dehors du conteneur "list-dropdown"
        elements.find(".btn--dropdown").removeClass("open");
        elements.removeClass("open");
      }
    });
  }

  dropdownFAQ() {
    let elements = $(".item-dropdown[data-action]");

    /* Opening and close dropdown */
    elements.click((e) => {
      if ($(e.currentTarget).hasClass("open")) {
        $(e.currentTarget).removeClass("open");
      } else {
        //elements.removeClass('open');
        $(e.currentTarget).addClass("open");
      }
    });
  }
}

export default ComponentForm;
