import $ from "jquery";

class OffersServices {
    constructor() {

    }

    init() {
        $(document).ready( () => {
            this.container =  $('.bl-os');
            self = this;

            this.container.each(  function (key, value) {
                self.initSlider(value);
            });
        });
    }
    initSlider(elem) {
        //console.log(this.container.find('.arrow-slick-custom'));
        $(elem).find('.bl-os__slider').slick({
            dots: false,
            autoplay: false,
            arrows: true,
            variableWidth: true,
            infinite: false,
            slidesToScroll: 3,
            appendArrows: $(elem).find('.arrow-slick-custom'),
        });
    }
}

export default OffersServices;
