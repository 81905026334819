import $ from "jquery";

class EventCalendar {
  constructor() {}

  init() {
    $(document).ready(() => {
      /*
       * Conf / base
       */
      this.container = $(".bl-evc");
      if (!this.container.length) {
        return false;
      }
      this.taxonomies_filters_saved = null;
      this.month_filters_saved = null;
      this.posts_per_page = -1;
      this.type_calendar = this.container.data("type-calendar");
      this.type_item = this.container.data("type-item");
      this.type_view = this.container.data("type-view");
      this.ajaxArea = this.container.find(".ajax-area .row");
      this.ajaxAreaPopin = $("#area-ajax-popin-event-booking");
      this.form_taxonomies_filter = this.container.find(
        "form.events-taxonomies-filters"
      );
      this.form_months = this.container.find("form.list-date");
      this.form_months_inputs = this.form_months.find('input[type="checkbox"]');
      this.include_pro = this.container.data("type-pro");

      /*
       * Init type calendar
       */

      // if(this.type_calendar === 'type-card-and-list') // Module home
      // {
      //     this.initCalendarTypeCardAndList();
      // }

      // if(this.type_calendar === 'type-list-months') // Module page event
      // {
      //     this.initCalendarTypeListMonths();
      // }

      /*
       * Action filter tax
       */
      this.form_taxonomies_filter.submit((e) => {
        e.preventDefault();

        this.taxonomies_filters_saved = this.form_taxonomies_filter
          .find("input:checked")
          .map(function (idx, elem) {
            return $(elem).val();
          })
          .get();

        this.ajaxRequest();
      });

      this.form_months.submit((e) => {
        e.preventDefault();

        this.month_filters_saved = this.form_months
          .find("input:checked")
          .map(function (idx, elem) {
            return $(elem).val();
          })
          .get();
        this.type_calendar = "type-list-months";

        this.ajaxRequest();

        // Uncheck all inputs
        this.form_months.find("input[type='checkbox']").prop("checked", false);
      });

      var formMonths = this.form_months; // Stocker une référence à this.form_months

      this.form_months.find("input[type='checkbox']").change(function (e) {
        // Désélectionner les autres cases à cocher
        formMonths
          .find("input[type='checkbox']")
          .not(this)
          .prop("checked", false);
        formMonths
          .find("input[type='checkbox']")
          .not(this)
          .closest("label")
          .removeClass("checked");
      });

      let self = this;
      $(".btn-square--icon-load").click(function () {
        // Réinitialiser les formulaires
        self.month_filters_saved = "";
        self.taxonomies_filters_saved = "";

        $(".list-dropdown").removeClass("items-active");

        $('form.events-taxonomies-filters').find("input[type='checkbox']").prop("checked", false);
        $('.list-dropdown__content__label').removeClass('checked');

        // Exécuter la requête Ajax pour récupérer les données d'origine
        self.ajaxRequest();
      });
    });
  }

  initCalendarTypeCardAndList() {
    this.btn_view_card = this.container.find(
      'button[data-action="change-view-card"]'
    );
    this.btn_view_list = this.container.find(
      'button[data-action="change-view-list"]'
    );

    if (this.type_view == "view-card") {
      this.form_months.css({ display: "none" });
      this.initSliderCards();
    }
    this.initSlideMonth();

    this.btn_view_card.click((e) => {
      this.btn_view_list.removeClass("active");
      this.btn_view_card.addClass("active");
      this.posts_per_page = 8;
      this.type_view = "view-card";
      this.type_item = "item-card";
      this.ajaxRequest();
      this.form_months.css({ display: "none" });
    });

    this.btn_view_list.click((e) => {
      this.btn_view_card.removeClass("active");
      this.btn_view_list.addClass("active");
      this.posts_per_page = -1;
      this.type_view = "view-list";
      this.type_item = "item-list";

      /*
       * Select first month
       */
      this.month_filters_saved = this.form_months
        .find(".slick-slide:first-child li input")
        .val();

      /*
       * Styling btn date
       */
      this.form_months_inputs.closest("label").removeClass("checked");
      this.form_months
        .find(".slick-slide:first-child li input")
        .closest("label")
        .addClass("checked");

      this.ajaxRequest();
      this.form_months.css({ display: "flex" });
    });
  }

  initSliderCards() {
    let args = {
      dots: false,
      autoplay: false,
      arrows: false,
      variableWidth: true,
      infinite: false,
      lazyLoad: "image",
      slidesToScroll: 1,
      swipeToSlide: true,
    };

    if (this.sliderCards && this.sliderCards.hasClass("slick-initialized")) {
      this.sliderCards.slick("unslick");
    }

    this.sliderCards = this.container.find(".bl-evc__list").slick(args);

    $(window).on("load resize", () => {
      if ($(window).width() > 575) {
        if (this.sliderCards.hasClass("slick-initialized")) {
          this.sliderCards.slick("unslick");
        }
        return;
      }
      if (!this.sliderCards.hasClass("slick-initialized")) {
        return this.sliderCards.slick(args);
      }
    });
  }

  //   initCalendarTypeListMonths() {
  //     this.initSlideMonth();
  //   }

  //   initSlideMonth() {
  //     this.slider_months = this.form_months.find(".slide");
  //     this.slider_months.slick({
  //       dots: false,
  //       autoplay: false,
  //       arrows: false,
  //       variableWidth: true,
  //       infinite: false,
  //       swipeToSlide: true,
  //     });
  //   }

  ajaxRequest() {
    let self = this;
    self.ajaxArea.html(""); // Remove content
    self.container.addClass("show-loader"); // Show Loader pending request
    $.ajax({
      url: adminAjax,
      method: "POST",
      data: {
        action: "ajax_events_calendar",
        filters_taxonomies: self.taxonomies_filters_saved,
        filters_months: self.month_filters_saved,
        type_calendar: self.type_calendar,
        type_view: self.type_view, // for homepage example
        type_item: self.type_item,
        post_per_page: self.posts_per_page,
        event_pro: self.include_pro,
      },
      success: function (data) {
        if (data.success) {
          self.ajaxArea.html(data.data.data);
          self.ajaxAreaPopin.html(data.data.data_popin_booking);
          self.hidePagination(data.data.data_pagination);
        } else {
          self.ajaxArea.html(data.data);
        }

        self.container.removeClass("show-loader"); // Remove loader after request
      },
      error: function (data) {
        console.log('Erreur ...');
      },
    });
  }

  hidePagination(data_pagination = null) {
    if (data_pagination !== null) {
      this.container.find(".pagination-evc").html(data_pagination);
    } else {
      this.container.find(".pagination-evc").html("");
    }
  }

  createCookies() {}

  updateCookies() {}

  removeCookies() {}
}

export default EventCalendar;
