import $ from "jquery";

class readMore {
  constructor() {}

  init() {
    $(document).ready(() => {
      this.readMore();
    });
  }

  readMore() {
    $(".text-wrapper").each(function () {
      var html = $(this).html().split(" ");
      html =
        html.slice(0, 50).join(" ") +
        '<span class="dots">...</span><div class="full-text">' +
        html.slice(50).join(" ");
      $(this).html(html);
    });

    $(".full-text").hide();

    $(".readmore-btn").click(function (event) {
      event.preventDefault();
      var dots_visibility = $(this).siblings(".text-wrapper").find("span.dots");
      dots_visibility.html(dots_visibility.html() == "..." ? " " : "...");
      $(".text-wrapper > p.p1").contents().unwrap();
      $(this).parent().find(".full-text").slideToggle("200");
      $(this).html($(this).html() == "Voir moins" ? "Voir plus" : "Voir moins");

      const paragraphElement = $('.text-wrapper').find('p:nth-last-child(-n+2)');
      paragraphElement.css('display', 'inline');
    });
  }
}

export default readMore;
