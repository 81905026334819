import $ from "jquery";
import Masonry from "masonry-layout";

class WallImg {
    constructor() {

    }

    init() {
        window.onload = () => {
          const grid = document.querySelector('.wall-img');
          const masonry = new Masonry(grid, {
            itemSelector: '.wall-img__item',
            gutter: 10,
            horizontalOrder: true
          });
        }
    }


}

export default WallImg;