import $ from "jquery";

class Alert {
    constructor() {

    }

    init() {
        $(document).ready(() => {



            /*** Alert gestion ***/
            this.container = $('.bl-alert');
            this.btnClose = this.container.find('.bl-alert__close');
            this.remove = false;

            /*
            * Get choice user open or hide panel
             */
            if(sessionStorage.closePanel === '1' ) {
                return; // Stop generate actions
            } else {
                this.container.addClass('show');
            }

            let previousPlacement = 0;
            $(window).scroll( (e) => {
                if(window.pageYOffset < previousPlacement || window.pageYOffset == 0) {
                    this.show();
                } else {
                    this.close();
                }

                previousPlacement = window.pageYOffset;
            });
            

            this.btnClose.click( () => {
                this.close();
                this.closeSession();
                this.remove = true;
            });

            /*** Countdown ***/
            this.countdowns = $('.js-countdown');
            if(this.countdowns) {
                this.mms_jour = 24 * 60 * 60 * 1000;
                this.mms_heure = 60 * 60 * 1000;
                this.mms_minute = 60 * 1000;
                this.mms_seconde = 1000;
                this.date_end = new Date(this.countdowns.data('end-time'));


                this.initCountdown()
            }
        })
    }

    closeSession() {
        sessionStorage.setItem('closePanel', '1');
    }

    close() {
        this.container.addClass('hide');
    }

    show() {
        if (!this.remove) {
            this.container.removeClass('hide');
        }
    }

    initCountdown() {

        this.date_current = new Date();

        //console.log(this.date_current.getDate())
        //console.log(this.date_current.getTime())

        //console.log(this.date_end.getDate())
        //console.log(this.date_end.getTime())

        // milliseconds between the dates
        let diff_mms = this.date_end.getTime() - this.date_current.getTime();

        // Days
        let diff_days = Math.floor(diff_mms / this.mms_jour);
        diff_mms -= diff_days * this.mms_jour;

        // Hours
        let diff_hours = Math.floor(diff_mms / this.mms_heure);
        diff_mms -= diff_hours * this.mms_heure;

        // Minutes
        let diff_minutes = Math.floor(diff_mms / this.mms_minute);
        diff_mms -= diff_minutes * this.mms_minute;

        // seconds
        let diff_seconds = Math.floor(diff_mms / this.mms_seconde);

        this.container.find('.hours .date').html('').html(diff_hours);
        this.container.find('.minutes .date').html('').html(diff_minutes);
        this.container.find('.seconds .date').html('').html(diff_seconds);

        setTimeout(() => { this.initCountdown() }, 1000);
    }
}

export default Alert;
