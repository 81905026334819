import $ from "jquery";

class HeaderSlider {
    constructor() {

    }

    init() {
        $(document).ready( () => {
            this.sliderContainer = $('.bl-hs__list');
            if(!this.sliderContainer.length) { return false;}
            this.slider = $('.bl-hs__list');
            this.configSlick();
        });
    }

    configSlick() {
        var time = 6;
        var isPause,
            tick,
            percentTime,
            progressBar;
        var slider = this.slider;


        this.slider.slick({
            dots: false,
            infinite: true,
            //autoplay: true,
            animateDuration: 1000,
            //autoplaySpeed: 6000,
            //appendArrows: '.bl-hs .bl-hs__area-btn',
            nextArrow: '.bl-hs .bl-hs__area-btn .slick-next',
            prevArrow: '.bl-hs .bl-hs__area-btn .slick-prev',
            slidesToShow: 1,
            pauseOnDotsHover: true,
            lazyLoaded: 'image'
        });


        progressBar = this.sliderContainer.closest('.bl-hs').find('.slick-next');
        this.sliderContainer.on({
            mouseenter: function() {
                isPause = true;
            },
            mouseleave: function() {
                isPause = false;
            }
        });

        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            isPause = false;
            tick = setInterval(interval, 10);
        }

        function interval() {
            if(isPause === false) {
                percentTime += 1 / (time+0.1);
                progressBar.find('.animate-bg').css({
                    background: 'linear-gradient(90deg, rgba('+colorSiteRGB+',1) 0%, rgba('+colorSiteRGB+',1) '+percentTime+'%, rgba(0,0,0,0) '+percentTime+'%, rgba(0,0,0,0) 100%)',
                });

                progressBar.find('.icon').css({
                    background: 'linear-gradient(90deg, rgba('+colorSiteRGB+',1) 0%, rgba(0,0,0,1) '+percentTime+'%, rgba(255,255,255,1) '+percentTime+'%, rgba(255,255,255,1) 100%)',
                });

                if(percentTime >= 100)
                {
                    slider.slick('slickNext');
                   startProgressbar();
                }
            }
        }


        function resetProgressbar() {
            progressBar.find('.animate-bg').css({
                background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%)',
            });

            progressBar.find('.icon').css({
                background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
            });
            clearTimeout(tick);
        }

        startProgressbar();
    }
}

export default HeaderSlider;
