import $ from "jquery";

class MenuMobile {
    constructor() {

    }

    init() {
        $(document).ready( () => {
            this.btnOpenMenu = $('.burger-menu-mobile');
            this.body = $('body');
            this.btnOpenMenu.click( () => {
                this.toggleMenu();
            });
        });
    }

    toggleMenu() {
        if (this.body.hasClass('menu-is-open')) {
            this.body.removeClass('menu-is-open');
            this.btnOpenMenu.removeClass('menu-is-open');
        } else {
            this.body.addClass('menu-is-open');
            this.btnOpenMenu.addClass('menu-is-open');
        }
    }
}

export default MenuMobile;
