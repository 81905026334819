import $ from "jquery";
import "slick-carousel/slick/slick";
import MicroModal from "micromodal";
import AOS from "aos";
import "lazyload/lazyload";

import MenuMobile from "./partials/MenuMobile";
import HeaderSlider from "./blocks/HeaderSlider";
import ComponentForm from "./components/ComponentForm";
import EventCalendar from "./blocks/EventCalendar";
import OffersServices from "./blocks/OffersServices";
import BookingEvent from "./blocks/BookingEvent";
import ListNewsAjax from "./blocks/ListNewsAjax";
import PracticalInformations from "./blocks/PracticalInformations";
import GalerieMedia from "./blocks/GalerieMedia";
import GalerieMediaSecond from "./blocks/GalerieMediaSecond";
import WallImg from "./blocks/WallImg";
import Alert from "./components/Alert";
import Search from "./components/Search";
import ProgressBar from "./components/ProgressBar";
import ReadMore from "./components/ReadMore";

const progressBar = new ProgressBar();
progressBar.init();

const menuMobile = new MenuMobile();
const headerSlider = new HeaderSlider();
const componentForm = new ComponentForm();
const eventCalendar = new EventCalendar();
const offersServices = new OffersServices();
const bookingEvent = new BookingEvent();
const listNewsAjax = new ListNewsAjax();
const practicalInformations = new PracticalInformations();
const galerieMedia = new GalerieMedia();
const galerieMediaSecond = new GalerieMediaSecond();
const wallImg = new WallImg();
const rAlert = new Alert();
const search = new Search();
const readMore = new ReadMore();

readMore.init();
menuMobile.init();
headerSlider.init();
componentForm.init();
eventCalendar.init();
offersServices.init();
bookingEvent.init();
listNewsAjax.init();
practicalInformations.init();
galerieMedia.init();
galerieMediaSecond.init();
wallImg.init();
rAlert.init();
search.init(10, 3);
/*
 * Add lazyload for ajax added img.lazyload
 */

/*
$(document).on('DOMNodeInserted',  function(e) {
    let lazyLoadAjaxElems = $(e.currentTarget).find('img.lazyload');

    if (lazyLoadAjaxElems.length ) {
        lazyload(lazyLoadAjaxElems);
    }
});
*/

$(document).ready(function () {
  lazyload();

  AOS.init({
    duration: 1500,
    offset: 0, // offset (in px) from the original trigger point
    delay: 800, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom",
  });
});

/*
Global modal
 */
$(document).ready(function () {
  MicroModal.init({
    disableScroll: true,
  });

  $("body").on("click", "[data-micromodal-trigger]", function (e) {
    let target = $(this).data("micromodal-trigger");
    let localizeTarget = $("body").find(".modal#" + target);

    if ($(localizeTarget).length) {
      MicroModal.show($(this).data("micromodal-trigger"));
    }

    $(".modal-booking-event .head-event .pricestart a").click(function () {
      console.log($(this).closest(".modal"));
      MicroModal.close($(this).closest(".modal").attr("id"));
    });
  });
});

/*
 * hack newsletter add icon
 */

$(document).ready(function () {
  $('.bl-newsletter form input[type="submit"]')
    .wrap(
      '<div class="custom-submit-newsletter"><div class="position"></div></div>'
    )
    .before("<span></span>");

  if ($(".mailjet_widget_form_message").length > 0) {
    if ($(".mailjet_widget_form_message").html().length > 0) {
      $(".mailjet_widget_form_message").addClass("show");
    }
  }
});

$(document).on("DOMNodeInserted", function (e) {
  if ($(e.target).hasClass("mailjet_widget_form_message")) {
    $(e.target).addClass("show");
  }
});

/*
 * Hack WCF7
 */
$(document).on("DOMNodeInserted", function (e) {
  if ($(e.target).hasClass("wpcf7-not-valid-tip")) {
    $(e.target).closest(".form-row").addClass("error");
  }
});

$(document).on("DOMNodeRemoved", function (e) {
  if ($(e.target).hasClass("wpcf7-not-valid-tip")) {
    $(e.target).closest(".form-row").removeClass("error");
  }
});

/*
 * Slider hall of frame
 */
$(document).ready(function () {
  $(".bl-shall__slider").slick({
    dots: false,
    autoplay: false,
    arrows: true,
    variableWidth: true,
    infinite: false,
    slidesToScroll: 3,
    appendArrows: ".bl-shall .arrow-slick-custom",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  });
});

/*
 * Parallax banner
 */
$(document).ready(function () {
  bannerParallax();
  $(window).on("scroll resize ready", function () {
    bannerParallax();
  });
});

function bannerParallax() {
  let blBanner = $(".bl-banner");
  //console.log('init banner');

  if (!blBanner.length) {
    return;
  }
  if (
    $(window).scrollTop() + window.innerHeight >= blBanner.offset().top &&
    window.pageYOffset < blBanner.offset().top + blBanner.outerHeight()
  ) {
    blBanner.prev().addClass("parallax");
    //console.log('show');
  } else {
    blBanner.prev().removeClass("parallax");
    //console.log('hidde');
  }
}

/*
 * Hide menu scroll
 */
let previousPlacement = 0;
$(window).scroll(function (e) {
  if (window.pageYOffset < previousPlacement || window.pageYOffset == 0) {
    //console.log('show');
    $("header.header-main").removeClass("hide");
  } else {
    //console.log('hide');
    $("header.header-main").addClass("hide");
  }

  previousPlacement = window.pageYOffset;
});
