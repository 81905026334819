import $ from "jquery";

class ProgressBar {
    constructor() {

    }

    init() {
        document.onreadystatechange = (e) => {
            if(document.readyState=="interactive")
            {
                this.all = document.getElementsByTagName("*");
                for (var i=0, max=this.all.length; i < max; i++)
                {
                    this.set_ele(this.all[i]);
                }
            }
        }
    }

    check_element(ele)
    {
        let per_inc=100/this.all.length;
        if($(ele).on())
        {

            //console.log(document.getElementById("progress_width"));
            let prog_width= per_inc+ Number(document.getElementById("progress_width").value);
            document.getElementById("progress_width").value = prog_width;
            $("#bar1").css('width', prog_width + "%");
        }

        else
        {
            this.set_ele(ele);
        }
    }

    set_ele(set_element)
    {
        this.check_element(set_element);
    }
}

export default ProgressBar;