import $ from "jquery";

class BookingEvent {
    constructor() {

    }

    init() {
        $(document).ready( () => {
            $(document).on('DOMNodeInserted', (e) => {
                if ( $(e.target).hasClass('modal-booking-event') ) {
                    this.initComponent($(e.target))
                }
            });

            this.initComponent($('.modal-booking-event'));
        });
    }

    initComponent(items) {
        items.each( (key, item) => {
            let checkboxs = $(item).find('input[type="radio"]');
            let rows_dates = $(item).find('.row-date');
            let btn_expend_tickets = rows_dates.find('.show-other-ticket');
            checkboxs.each( (key, checkbox) => {
                $(checkbox).change((e) => {
                    // Settings class css and display/hide
                    $(e.currentTarget).closest('.modal-booking-event').find('.row-default').hide();
                    $(e.currentTarget).closest('.modal-booking-event').find('.show').removeClass('show');
                    $(e.currentTarget).closest('.modal-booking-event').find('label').removeClass('checked');

                    // Show first line
                    $(e.currentTarget).closest('.custom-checkbox-js').find('label').addClass('checked');
                    $(e.currentTarget).closest('.modal-booking-event').find('.' + $(e.currentTarget).val()).addClass('show');
                    $(e.currentTarget).closest('.modal-booking-event').find('.' + $(e.currentTarget).val() + ' .row-booking').addClass('show');
                    $(e.currentTarget).closest('.modal-booking-event').find('.' + $(e.currentTarget).val() + ' .no-official').addClass('show');
                });
            });

            btn_expend_tickets.click( (e) => {
                $(e.currentTarget).closest('.row-date').find('.row-ticketing');

                if($(e.currentTarget).hasClass('show')) {
                    $(e.currentTarget).removeClass('show');
                    $(e.currentTarget).closest('.row-date').find('.row-ticketing').removeClass('show');
                } else {
                    $(e.currentTarget).addClass('show');
                    $(e.currentTarget).closest('.row-date').find('.row-ticketing').addClass('show');
                }
            });

        })
    }
}

export default BookingEvent;
