import $ from "jquery";

class Search {

    init(numberResults, minTextLenght) {
        $(document).ready(() => {
            this.minTextLenght = minTextLenght;
            this.numberResults = numberResults;
            this.container = $('.panel-search:not(.disable-ajax)');
            this.containerResults = this.container.find('.panel-search__results');
            this.btnOpenPanel = $('[data-action="open-search"]');
            this.btnClosePanel = $('[data-action="close-search"]');
            this.currentKeywords = '';

            this.input = this.container.find('.input-search');

            this.btnOpenPanel.click( () => {
                this.openPanel();
            });

            this.btnClosePanel.click( () => {
                this.closePanel();
            });


            this.input.keyup( (event) =>  {
                this.changeInputValue(event);
            });

        })
    }

    openPanel() {
        console.log("open");
        this.container.addClass('open');
    }

    closePanel() {
        console.log("close");
        $('body .input-search').val(''); // reset all inputs search (used for results search page
        this.container.removeClass('open');
    }

    changeInputValue(event) {
        this.currentKeywords = this.input.val();
        console.log(this.input.val());
        if( this.input.val().replace(/ /g,'').length >= this.minTextLenght){
            this.ajaxSearch();
        }else{
            this.displayDataReset();
        }
    }

    ajaxSearch() {
        //console.log('launch ajax request');
        let self = this;
        $.ajax({
            url : adminAjax,
            type : 'POST',
            data : {
                'action': 'search_autocomplete',
                'numberResults': this.numberResults,
                'currentKeywords' : this.currentKeywords
            },
            success : (data) => {
                console.log(data);
                console.log(this.containerResults);
                this.containerResults.addClass('open').find('ul').html('').html(data.data)
            },
            error : (response) => {
                //this.displayDataReset();
                console.log(response);
            }
        });
    }
}

export  default Search;


//let formHeaderDesktop = new Autocomplete('#form-search', 5, 2);
// let formHeaderMobile = new Autocomplete('#form-search-mobile', 5, 2);