import $ from "jquery";

class GalerieMedia {
    constructor() {

    }

    init() {
        $(window).on('load',  () => {
            this.container = $('.bl-gm');
            this.sliders = this.container.find('.bl-gm__list');
            this.initSlider();
        });
    }

    initSlider() {
        if(this.sliders.length >= 1 ) {
            $(this.sliders).each(function (key, target) {
                let elem = $(target);
                elem.slick({
                    dots: false,
                    autoplay: false,
                    arrows: true,
                    infinite: false,
                    variableWidth: false,
                    adaptiveHeight: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    appendArrows: $(elem).closest('.bl-gm').find('.arrow-slick-custom'),
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                swipeToSlide: true,
                            }
                        }
                    ]
                });

            });
        }
    }
}

export default GalerieMedia;
