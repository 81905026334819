import $ from "jquery";

class PracticalInformations {
    constructor() {

    }

    init() {
        $(document).ready( () => {
            /*
            * Get
            */
            this.container = $('.bl-pi');
            if(!this.container.length) { return false;}
            this.form = this.container.find('form');
            this.baseUrl = 'https://maps.google.com/maps/dir/?api=1&';
            this.travelBtns = this.form.find('.list-transport button');

            /*
            * Set
            */
            this.paramsUrl = {
                'hl' : 'fr',
                'origin' : null,
                'destination' : null,
                'travelmode': this.form.find('.list-transport button.active').val(),
            };

            /*
            * Actions
            */

            this.form.find('input[name="origin"]').keyup( (e) =>  {
                if( $(e.currentTarget).val().replace(/ /g,'').length >= 1) {
                    this.form.find('button[type="submit"]')
                        .removeClass('btn--disable')
                        .addClass('btn--primary');
                } else {
                    this.form.find('button[type="submit"]')
                        .removeClass('btn--primary')
                        .addClass('btn--disable');
                }
            });

            this.travelBtns.click( (e) => {
                this.travelBtns.removeClass('active');
                $(e.currentTarget).addClass('active');
                this.paramsUrl.travelmode = $(e.currentTarget).val();
            });

            this.form.submit( (e) => {
               e.preventDefault();

                this.adressRoom =  this.form.find('input[name="destination"]');
                this.adressStart =  this.form.find('input[name="origin"]');

                if ( this.adressRoom.val().length && this.adressStart.val().length ) {

                    this.paramsUrl.origin = this.adressStart.val();
                    this.paramsUrl.destination = this.adressRoom.val();
                    this.generateUrl();
                }
            });
        });
    }

    goToMaps()
    {
       // this.baseUrl =  https://www.google.com/maps/search/?api=1&query=******;
    }

    generateUrl() {
        this.finalUrl = this.baseUrl + $.param(this.paramsUrl);
        if( this.finalUrl.length) {
            window.open(this.finalUrl, '_blank');
        } else {
            alert('une erreur est survenue');
        }
    }
}

export default PracticalInformations;
